.app {
  background-image: url("./assets/bg.png");
  object-fit: cover;
  height: 100vh;
  color: lightgrey;
  position: fixed;
  width: 100%;
  overflow: auto;
  font-family: "IBM Plex Sans Condensed" !important;
}

.customCard {
  border: 1px solid lightgrey;
  background: white;
}

.cardImg {
  height: 400px;
  width: 100%;
  border: 1px solid #005bb1;
  object-fit: cover;
  @media screen and (max-width: 768px) {
    height: 200px;
  }
}

a {
  color: lightgray !important;
}

a:hover {
  border: none;
}

.text-overflow {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden !important;
  color: grey;
}
